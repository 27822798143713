import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import React, { useEffect, useState } from "react";
import { Routes , Route} from "react-router";
import { Link, useNavigate } from "react-router-dom";

import { Close } from "@mui/icons-material";
import { Box, Button, Fab } from "@mui/material";
import Cookies from "universal-cookie";
import LiveJobs from "./LiveJobs";
import RequestedJobs from "./RequestedJobs";
import OldJobs from "./OldJobs";
import Header from "../../comp/Header";
import Sidebar from "../../comp/Sidebar";
import JobMenu from "../../comp/JobMenu";
import { Add } from "@material-ui/icons";
const cookies = new Cookies();

const Jobs = () => {
    const [variable, setVariable] = useState("");
    const navigate = useNavigate();
    const [input, setInput] = useState("")

    useEffect(() => {
        if (cookies.get("token")) {
        } else {
            navigate("/");
        }
    }, []);

    return (
        <div className="bg-[#EDEBEE] h-screen overflow-auto">
            <Header />


            <Box marginTop={"0rem"}>
                <Sidebar />
                {/*<Sidebar2/>*/}
                <TabsUnstyled defaultValue={0} className="m-2">
                    <TabsListUnstyled className="bg-white rounded-lg flex items-center justify-between text-neutral-400 px-4 py-2">
                        <div className="hidden md:flex items-center space-x-6">
                            <div
                                className={` text-xl font-semibold cursor-pointer
                            ${window.location.pathname === "/posted-jobs/live-jobs"
                                        ? " text-sky-700"
                                        : ""
                                    }
                            ${window.location.pathname ===
                                        "/posted-jobs/job-detail"
                                        ? " text-sky-700"
                                        : ""
                                    }
                            ${window.location.pathname ===
                                        "/posted-jobs/job-detail/survey-form"
                                        ? " text-sky-700"
                                        : ""
                                    }`}
                                onClick={() => {
                                    navigate("live-jobs");
                                }}
                            >
                                <div className="grid py-2 place-items-center">Live Jobs</div>
                            </div>
                            <div
                                className={`text-xl font-semibold cursor-pointer
                            ${window.location.pathname ===
                                        "/posted-jobs/requested-jobs"
                                        ? " text-sky-700"
                                        : ""
                                    } `}
                                onClick={() => {
                                    navigate("requested-jobs");
                                }}
                            >
                                <div className="grid py-2 place-items-center">
                                    Requested to Admin
                                </div>
                            </div>
                            <div
                                className={`text-xl font-semibold cursor-pointer
                            ${window.location.pathname ===
                                        "/posted-jobs/old-jobs"
                                        ? " text-sky-700"
                                        : ""
                                    } `}
                                onClick={() => {
                                    navigate("old-jobs");
                                }}
                            >
                                <div className="grid py-2 place-items-center">Old Job Posts</div>
                            </div>
                        </div>
                        <div className="md:hidden block">
                            <JobMenu />
                        </div>
                        <div className=" relative w-2/4  md:w-full md:max-w-lg">
                            <input
                                type="text"
                                value={input}
                                className="h-10 border border-gray-400  rounded pl-4 w-full  text-lg text-black"
                                placeholder="Search By Job Title"
                                onChange={(e) => setInput(e.target.value)}
                            />
                            <button
                                onClick={() => setInput("")}
                                className={`absolute right-2 top-2 text-[#7a7878] ${input ? "block" : "hidden"
                                    }`}
                            >
                                <Close className={`text-[#7a7878]`} />
                            </button>
                        </div>
                        <Box>
                            <Link to="/AddNewPosition">
                            <Button variant="outlined"
                                sx={{
                                    display: {
                                        md: "block",
                                        xs: "none"
                                    }
                                }}
                                >Add New Position</Button>
                            </Link>
                            <Link to="/AddNewPosition">
                                <Fab  color="primary" sx={{
                                    position: "fixed",
                                    bottom: 20,
                                    right: 30,
                                    display: {
                                        md: "none"
                                    }
                                }} variant="extended">
                                    <Add sx={{ mr: 1, fontSize: "20px" }} />
                                    Add
                                </Fab>
                            </Link>
                         </Box>

                    </TabsListUnstyled>


                    <TabPanelUnstyled
                        className="w-full"
                        value={0}
                        onClick={(e) => setVariable(e.currentTarget.id)}
                    ></TabPanelUnstyled>

                    <TabPanelUnstyled
                        className="w-full"
                        value={1}
                        onClick={(e) => setVariable(e.currentTarget.id)}
                    ></TabPanelUnstyled>

                    <TabPanelUnstyled
                        className="w-full"
                        value={2}
                        onClick={(e) => setVariable(e.currentTarget.id)}
                    ></TabPanelUnstyled>
                    <Routes>
                    <Route path="live-jobs" element={<LiveJobs input={input}/>} />
                    <Route path="requested-jobs" element={<RequestedJobs input={input}/>} />
                    <Route path="old-jobs" element={<OldJobs input={input}/>} />

                    </Routes>
                </TabsUnstyled>
            </Box>
        </div>
    );
};

export default Jobs;
