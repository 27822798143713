import { message } from "antd";
import BasicHttpResponse from "../model/BasicHttpResponse";
import { HTTPStatus } from "./constants";

export function handleException(err) {
    switch (err.response.status) {
        case HTTPStatus.BAD_REQUEST:
            return new BasicHttpResponse({
                statusCode: HTTPStatus.BAD_REQUEST,
                errorMsg: "Plase provide all information",
            });
        case HTTPStatus.FORBIDDEN:
            return new BasicHttpResponse({
                statusCode: HTTPStatus.FORBIDDEN,
                errorMsg: err.response.statusText
            });
        default:
            return new BasicHttpResponse({
                statusCode: err.response.status,
                errorMsg: err.response.statusText,
            });
    }
}


/**
 * @param {BasicHttpResponse} exception
 * @param {any} navigate
 */
export function handleControllerResultException(exception, navigate) {
    if (exception.statusCode === HTTPStatus.UNAUTHORIZED) {
        localStorage.removeItem("userDetails");
        navigate("/login");
    } else {
        message.error(exception.error);
    }
}
