import {
    ArrowBackIos,
    Edit,
    FormatListBulleted,
    MoreVert,
    People
} from "@mui/icons-material";
import {
    Box,
    Button,
    Container,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Typography
} from "@mui/material";
import { grey } from "@mui/material/colors";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import Header from "../../comp/Header";
import LabeledText from "../../comp/LabeledText";
import RichDescription from "../../comp/RichDescription/RichDescription";
import { createdAtToDate } from "../../utils/helperFunction";
import JobAppliedApplicantsModal from "./JobAppliedApplicantsModal";

const cookies = new Cookies();

const JobDetails = () => {
  const navigate = useNavigate();
  const [position_name, setPositionName] = useState("");
  const [employment_type, setEmplomentType] = useState("");
  const [stipend, setStipend] = useState("");
  const [role_sub_category_name, setRole_sub_category_name] = useState("");
  const [role_category_name, setRole_category_name] = useState("");
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState("");
  const [criteria, setEducation] = useState("");
  const [functional_area, setFunctionalArea] = useState("");
  const [skills, setSkills] = useState("");
  const [job_description, setDisc] = useState("");
  const [jobId, setJobId] = useState("");
  const [updated_at, setUpdatedAt] = useState("");
  const [openMessageModal, setOpenMessageModal] = useState(false)
  const [anchorOptions, setAnchorOptions] = useState(null);
  const openOptions = Boolean(anchorOptions);
  const {id} = useParams()

  async function getJob(id) {
    await axios
      .get(`hri_company/jobs/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        console.log(resp.data);
        setPositionName(resp.data.position_name);
        setEmplomentType(resp.data.employment_type);
        setStipend(resp.data.stipend);
        setRole_sub_category_name(resp.data.role_sub_category_name);
        setRole_category_name(resp.data.role_category_name);
        setExperience(resp.data.experience);
        setLocation(resp.data.location);
        setEducation(resp.data.criteria);
        setFunctionalArea(resp.data.functional_area);
        setSkills(resp.data.skills);
        setDisc(resp.data.job_description);
        setJobId(resp.data.id);
        setUpdatedAt(resp.data.updated_at);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const date = createdAtToDate(updated_at)

  useEffect(() => {
   if(id) {
    getJob(id)
   }
  }, [id]);

  return (
    <Box>
            <Header />
            <Container maxWidth="lg" sx={{ marginX: "auto", paddingY: "5rem" }}>
                <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={() => navigate(-1)}
                            >
                                <ArrowBackIos />
                                Return
                            </Button>
                        </Grid>
                    <Grid item xs={6}>
                        <LabeledText
                            heading="Date:"
                            text={date}
                            sx={{ display: "flex", alignItems: "center" }}
                            textStyles={{ marginLeft: "5px" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box display={"flex"} justifyContent={"flex-end"}>
                                <>
                                    <Button
                                        onClick={e =>
                                            setAnchorOptions(
                                                anchorOptions === null
                                                    ? e.currentTarget
                                                    : null
                                            )
                                        }
                                    >
                                        <MoreVert />
                                    </Button>
                                    <Menu
                                        components={Paper}
                                        open={openOptions}
                                        anchorEl={anchorOptions}
                                        onClose={() => setAnchorOptions(null)}
                                        elevation={2}
                                    >
                                        <MenuItem
                                            onClick={() =>
                                                setOpenMessageModal(true)
                                            }
                                        >
                                            <FormatListBulleted
                                                fontSize="small"
                                                sx={{ marginRight: "10px" }}
                                            />
                                            Applicant List
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => navigate("/JobResponse")}
                                        >
                                            <People
                                                fontSize="small"
                                                sx={{ marginRight: "10px" }}
                                            />
                                            Survey Responses
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                navigate(`/editJobDetails/${jobId}`)
                                            }
                                        >
                                            <Edit
                                                fontSize="small"
                                                sx={{ marginRight: "10px" }}
                                            />
                                            Edit
                                        </MenuItem>
                                    </Menu>
                                </>
                            
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Position"
                            text={position_name}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            sx={{
                                textAlign: {
                                    md: "left",
                                    xs: "right",
                                },
                            }}
                            heading="Experience"
                            text={experience}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Employment Type"
                            text={employment_type}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            sx={{
                                textAlign: {
                                    md: "left",
                                    xs: "right",
                                },
                            }}
                            heading="Location"
                            text={location}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Salary/Stipend"
                            text={stipend}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            sx={{
                                textAlign: {
                                    md: "left",
                                    xs: "right",
                                },
                            }}
                            heading="Role"
                            text={role_sub_category_name}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Role Cateogory"
                            text={role_category_name}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            sx={{
                                textAlign: {
                                    md: "left",
                                    xs: "right",
                                },
                            }}
                            heading="Education"
                            text={criteria}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <LabeledText
                            heading="Functional Area"
                            text={position_name}
                        />
                    </Grid>
                    {skills !== "" && (
                        <Grid item xs={12}>
                            <Typography fontSize={"16px"}>Skills</Typography>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                flexWrap={"wrap"}
                            >
                                {skills
                                    .split(",")
                                    .map((data, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                backgroundColor: grey[200],
                                                padding: "5px 20px",
                                                borderRadius: "20px",
                                                margin: "10px",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                            }}
                                        >
                                            {data}
                                        </Box>
                                    ))}
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography fontSize={"16px"}>Description</Typography>
                        <RichDescription>
                            {job_description}
                        </RichDescription>
                    </Grid>

                </Grid>
            </Container>
            <JobAppliedApplicantsModal
                open={openMessageModal}
                onClose={() => setOpenMessageModal(false)}
                jobId={id}
            />
        </Box>
  );
};

export default JobDetails;
