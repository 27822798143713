import React, { useState } from "react";
import Chart from "react-apexcharts";

const LineChart = ({ jobTitle, jobNumber }) => {
    const [width, setWidth] = useState(window.innerWidth)
    const options = {
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: jobTitle,
            labels: {
                style: {
                    colors: "#212121",
                    fontSize: "13px"
                }
            }
        },
        colors: ['#f47536', '#E91E63', '#9C27B0'],
        yaxis: {
            min: 0,
            max: 10,
            labels: {
                style: {
                    padding: 10
                }
            }
        },


    }
    const series = [
        {
            name: "Jobs",
            data: jobNumber
        }
    ]

    const breakpoint = 400;
    return (
        <div>
      <div className="bg-white w-full h-[350px] rounded-lg text-xl font-bold">
        <div className="flex items-center bg-[#0856B6] p-2 rounded-t-lg">
          <h1 className="text-white">Top 5 Jobs</h1>
        </div>

        <div className="flex justify-center">
          <div className="flex justify-center h-[300px] items-center px-10">
          <Chart
                        options={options}
                        series={series}
                        type="bar"
                        width={width < breakpoint ? "280" : '350'}
                        height='300'
                    />
          </div>
        </div>
      </div>
    </div>
    );
};

export default LineChart;