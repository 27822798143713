import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { handleControllerResultException, handleException } from '../../utils/dataFetchHelper';
import axios from 'axios';
import Cookies from 'universal-cookie';
import JobList from '../../comp/jobs/JobList';
import { Box, Pagination } from '@mui/material';
import Spinner from '../../comp/Spinner';
import { EmptyState } from '../EmptyState/EmptyState';

const cookies = new Cookies();

export default function LiveJobs ({input}) {

    const navigate = useNavigate();
    const [card_details, setCard_details] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);

  async function searchText() {
    setLoading(true);
    GetDetails(input);
    setLoading(false);
  }

  useEffect(() => {
    let timeOut = 0;

    timeOut = setTimeout(() => {
      searchText();
    }, [700]);

    return () => {
      clearTimeout(timeOut);
    };
  }, [input]);



  const GetDetails = (input = "", page = 1) => {
    axios
      .get(`hri_company/jobs?jobs_type=Active&keyword=${input}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        }
      })
      .then((res) => {

        setCard_details(res.data.results);
        setTotalPages(Math.ceil(res.data.count / 20));
        setLoading(false);
      })
      .catch((err) => {
        const errRes = handleException(err);
        handleControllerResultException(errRes, navigate)
      });
  };


  useEffect(() => {
    GetDetails();
  }, []);

  const cardAction = (item) => {
    navigate(`/posted-jobs/job-details/${item.id}`);
    localStorage.setItem("JobId", item.id);
  };

    return (
        <div className="m-1 md:m-2 lg:m-3">
            {typeof card_details !== "string" && card_details.length > 0 ? (
                <div>
                <JobList
                    jobList={card_details}
                    actionButtonText={"view details"}   
                    actionButtonFunc={cardAction}    
                />
                <Box
                  sx={{ display: "flex", justifyContent: "center",padding:2 }}
                  marginBottom={"2rem"}
                >
                  <Pagination
                    count={totalPages}
                    color="primary"
                    shape="rounded"
                    onChange={(e, page) => {
                      GetDetails(input,page);
                    }}
                  />
                </Box>
                </div>
            ) : (
                <div>
                  {loading ? <Spinner /> : 
                  <EmptyState
                    title="No Jobs found"
                    message="No Jobs for Today.Take this time to focus on your personal growth, professional development, and enjoy the freedom to plan your day as you see fit."
                    img="/images/empty-states/NoJobs.png"
                    btn="Edit Profile"
                    btnLink="/profile"
                  />}
                </div>
                )}
        </div>
    )
}
