import axios from "axios";
import { BackendAPI } from "../constants/backendAPI";
import Cookies from "universal-cookie";
import { createJobRoleObj } from "../model/JobRoleModel";
import HttpResponse from "../model/HttpResponseModel";
import { createJobObj } from "../model/JobModel";
import { createUserApplicationObj } from "../model/UserApplicationModel";
import { handleException } from "../utils/dataFetchHelper";

const cookies = new Cookies();

/**
 * @typedef {object} JobParam
 * @property {string} position_name
 * @property {string} experience
 * @property {string} employment_type
 * @property {string} location
 * @property {string} stipend
 * @property {string} criteria
 * @property {string} role_sub_category
 * @property {string} functional_area
 * @property {string} job_description
 * @property {string} skills
 * @property {string} company
 * @property {string } assessment
 * @property {string} is_assessment
 * @property {string} country_city_state_fe_id
 */

/**
 * @param {JobParam} param
 */
export async function createNewJob({
    position_name,
    experience,
    employment_type,
    location,
    stipend,
    criteria,
    role_sub_category,
    functional_area,
    job_description,
    skills,
    company,
    assessment,
    is_assessment,
    country_city_state_fe_id,
}) {
    try {
        const res = await axios.post(
            BackendAPI.job.createJob,
            {
                position_name,
                experience,
                employment_type,
                location,
                stipend,
                criteria,
                role_sub_category,
                functional_area,
                job_description,
                skills,
                company,
                assessment,
                is_assessment,
                country_city_state_fe_id,
            },
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Job is added", null);
    } catch (err) {
        return handleException(err);
    }
}

/**
 * @param {string | undefined} jobId
 * @returns {Promise<[{educationList:string[], roleCategoryList:import("../models/JobRoleModel").JobRoleModel[], jobDetails:import("../models/JobModel").JobModel | null  }|null, import("../models/HttpResponse").HttpResponse | null]>}
 */
export async function initialJobFormPageData(jobId = undefined) {
    const fetchEducationList = await axios.get(
        BackendAPI.education.educationList,
        {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            },
        }
    );

    const fetchJobRole = await axios.get(BackendAPI.jobRole.roleCategory, {
        headers: {
            Authorization: `Token ${cookies.get("token")}`,
        },
    });

    const result = [null, null];
    const fetchList = [fetchEducationList, fetchJobRole];

    if (jobId !== undefined) {
        const fetchJobDetails = await axios.get(
            `/hri_company/jobs/${jobId}`,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        fetchList.push(fetchJobDetails);
    }

    try {
        const res = await Promise.all(fetchList);
        const jobRoleList = [];
        for (let x = 0; x < res[1].data.length; x++) {
            jobRoleList.push(createJobRoleObj(res[1].data[x]));
        }

        result[0] = {
            educationList: res[0].data.education_list,
            roleCategoryList: jobRoleList,
            jobDetails: jobId ? createJobObj(res[2].data) : null,
        };
        return result;
    } catch (err) {
        console.log(err)
        result[1] = handleException(err);
        return result;
    }
}

/**
 *
 * @param {string} jobId
 * @param {"public"|"protected"} visibility
 * @returns {Promise<[import("../models/JobModel").JobModel|null, HttpResponse|null]>}
 */

export async function getJobDetails(jobId, visibility = "protected") {
    const result = [null, null];
    try {
        const res = await axios.get(
            visibility === "protected"
                ? `${BackendAPI.job.jobDetails}/${jobId}`
                : `${BackendAPI.job.openJobDetail}/${jobId}`,
            {
                // headers: {
                //     Authorization: `Token ${cookies.get("token")}`,
                // },
            }
        );
        result[0] = createJobObj(res.data[0]);
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 *
 * @param {string} roleId
 * @returns {Promise<[import("../models/JobRoleModel").JobRoleModel[]|null, import("../models/HttpResponse").HttpResponse|null]>}
 */
export async function getRoleSubCategory(roleId) {
    const result = [null, null];
    try {
        const res = await axios.get(
            `${BackendAPI.jobRole.roleSubCategory}/${roleId}`,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        const roleArr = [];
        for (let data of res.data) {
            roleArr.push(createJobRoleObj(data));
        }
        result[0] = roleArr;
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 * @param {JobParam & {id:string}}
 */
export async function updateJobDetails({
    id,
    assessment,
    company,
    criteria,
    employment_type,
    experience,
    functional_area,
    is_assessment,
    job_description,
    location,
    position_name,
    role_sub_category,
    skills,
    stipend,
    country_city_state_fe_id,
}) {
    try {
        const res = await axios.put(
            `${BackendAPI.job.jobUpdate}/${id}`,
            {
                position_name,
                experience,
                employment_type,
                location,
                stipend,
                criteria,
                role_sub_category,
                functional_area,
                job_description,
                skills,
                company,
                assessment,
                is_assessment,
                country_city_state_fe_id,
            },
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Update successful", null);
    } catch (err) {
        return handleException(err);
    }
}

/**
 *
 * @param {string} jobId
 * @returns {Promise<[import("../model/UserApplicationModel").UserApplicationModel[]|null, HttpResponse | null]>}
 */
export async function jobApplicantList(jobId) {
    const result = [null, null];
    try {
        const res = await axios.get(
            `/hri_company/applicant-list/${jobId}`,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        const applicantArr = [];
        for (let x of res.data) {
            applicantArr.push(createUserApplicationObj(x));
        }
        result[0] = applicantArr;
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

export async function removeServeyQuestion(questionId) {
    try {
        const res = await axios.delete(
            `${BackendAPI.job.removeServeyQuestion}/${questionId}`,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, res.data.message);
    } catch (err) {
        return handleException(err);
    }
}


