import React, { useEffect, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ChatIcon from "@mui/icons-material/Chat";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

import Cookies from "universal-cookie";
import { Checkbox } from "@mui/material";
import { toast } from "react-toastify";
import { Collapse, Modal, Popover } from "antd";
import Spinner from "../Meeting/Spinner";
import Header from "../../comp/Header";
import { Bookmark, Save } from "@material-ui/icons";

const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const cookies = new Cookies();

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const UserManagement = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [status, setStatus] = useState("");
    const [resume, setResume] = useState("");
    const [resumeName, setResumeName] = useState("");
    const [detailsData, setDetailsData] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleDetails, setIsModalVisibleDetails] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleCancelDetails = () => {
        setIsModalVisibleDetails(false);
    };
    const onChange = (key) => {
        // console.log(key);
    };

    const getData = () => {
        axios
            .get(`hri_company/user-list`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {
                console.log("User Data", res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log("Error: ", err);
            });
    };

    const getDetails = (id) => {
        axios
            .get(`hri_company/application-user/${id}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {
                setIsModalVisibleDetails(true);
                setDetailsData(res.data);
            })
            .catch((err) => {
                console.log("Error Automating Resume", err);
            });
    };
    // console.log(detailsData);

    const bookmarkUser = () => {
        axios
            .post(`hri_company/user-bookmark`, {
                user: `${cookies.get("id")}`
            }, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {
                getData();
                toast.success(res.data.message || "Bookmarked user", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
            })
            .catch((err) => {
                toast.warn(`Cont Book mark User`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
                console.log("Error: ", err);
            });
    };


    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="bg-[#F1F1F1]">
            <Header />

            <div className="flex  space-x-4 rounded m-2 p-1.5 bg-white ">
                <div className="">
                    <div
                        className='px-3 py-1.5 font-bold text-lg md:text-xl rounded-md text-[#0865B6]'
                    >
                        Registered User
                    </div>

                </div>
            </div>
            <div className="overflow-auto mx-2 md:mx-4 rounded-lg mt-4 shadow  mb-20">
                <table className="w-full bg-white">
                    <thead className="bg-slate-200 border-b-2 border-gray-200 ">
                        <tr className="text-blue-700 w-[auto]">
                            <th className="w-[75px] text-left p-3 pl-20 text-lg font-semibold tracking-wide">
                                Applicants
                            </th>
                            <th className="w-[100px] p-3 text-lg font-semibold tracking-wide text-center">
                                Designation
                            </th>
                            <th className="w-[75px] p-3 text-lg font-semibold tracking-wide text-center">
                                Profile
                            </th>
                            <th className="w-[100px] p-3 text-lg font-semibold tracking-wide text-center">
                                User Details
                            </th>  
                            <th className="w-5 p-3 text-lg font-semibold tracking-wide text-center">
                                Bookmark
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300 text-center">
                        {data.length ? data.map((data) => (
                            <tr key={data.id} className="">
                                <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                    <div className="flex">
                                        <div className="w-12 h-12 ">
                                            <img
                                                className="rounded-full w-12 h-12"
                                                src={data.profile_pic}
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-4 text-left text-lg flex">
                                            <div className="self-center">
                                                {data.first_name} {data.last_name}
                                            </div>
                                            {/*<div*/}
                                            {/*    className='text-sm text-gray-400'>as Content Creater*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </td>

                                <td className="p-3 text-base text-gray-700 text-center whitespace-nowrap">
                                    <div className="flex justify-center space-x-3">
                                        {data.designation}
                                    </div>
                                </td>

                                <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                    <div className="flex justify-center gap-3">
                                        <div
                                            onClick={() => {
                                                setResume(data.id);
                                                setResumeName("");
                                                showModal();
                                            }}
                                            className="flex"
                                        >
                                            <div className="text-sm ml-2  cursor-pointer  font-bold whitespace-nowrap bg-blue-100 text-gray-600 px-4 py-3 rounded-lg w-28 truncate">
                                                View profile
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                {/* <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                    {data.status === "Pause" || data.status === "Block" ? (
                                        <div className="flex justify-center">
                                            <Button
                                                onClick={() =>
                                                    pauseUser(
                                                        data.user,
                                                        data.first_name,
                                                        data.last_name
                                                    )
                                                }
                                                sx={{ color: "#D6B300" }}
                                            >
                                                Pause Account
                                            </Button>
                                            <Button
                                            sx={{ color: "#D62600" }}
                                            >
                                                Block Account
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                onClick={() =>
                                                    pauseUser(
                                                        data.user,
                                                        data.first_name,
                                                        data.last_name
                                                    )
                                                }
                                                sx={{ color: "#44D600" }}
                                            >
                                                Activate Account
                                            </Button>
                                        </div>
                                    )}
                                    {/*{data.status === "Active" ? <div className="flex justify-center">*/}
                                {/*    <Button onClick={() => pauseUser(data.user, data.first_name, data.last_name)} sx={{color:"#44D600"}}>*/}
                                {/*        Activate Account*/}
                                {/*    </Button>*/}
                                {/*</div> : <></>}*/}
                                {/* </td> */}
                                <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                    <Button
                                        size="small"
                                        varaint="contained"
                                        style={{ backgroundColor: "#0865B6", color: "white" }}
                                        className="px-2"
                                        onClick={() => getDetails(data.id)}
                                    >
                                        Details
                                    </Button>
                                </td>
                                <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                    <Checkbox
                                        checked={data.company_bookmark}
                                        onClick={() => { cookies.set("id", data.user_id); bookmarkUser(); }}
                                        {...label}
                                        icon={<BookmarkBorderIcon />}
                                        checkedIcon={<BookmarkIcon />}
                                    />
                                </td>
                            </tr>
                        ))
                            :
                            <tr className="w-full">
                                <td></td>
                                <td></td>
                                <td>
                                    <div className="mx-auto w-auto">
                                        <Spinner />
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Modal
                title={data.fname}
                footer={false}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <iframe src={data} width="100%" className="h-96" title="..."></iframe>
            </Modal>
            <Modal
                title={"Details"}
                footer={false}
                visible={isModalVisibleDetails}
                onCancel={handleCancelDetails}
            >
                <div className="max-h-80 overflow-auto">
                    <div>
                        <div className="grid grid-cols-2 gap-y-2">
                            <div>
                                <span className="font-semibold  text-gray-600 text-lg">
                                    Applied :
                                </span>{" "}
                                <span className="font-semibold text-lg">
                                    {detailsData?.application_detail?.applied}{" "}
                                </span>
                            </div>
                            <div>
                                <span className="font-semibold  text-gray-600 text-lg">
                                    In-Review :
                                </span>{" "}
                                <span className="font-semibold text-lg">
                                    {detailsData?.application_detail?.in_review}
                                </span>
                            </div>
                            <div>
                                <span className="font-semibold  text-gray-600 text-lg">
                                    Interview :
                                </span>{" "}
                                <span className="font-semibold text-lg">
                                    {detailsData?.application_detail?.interview}
                                </span>
                            </div>
                            <div>
                                <span className="font-semibold  text-gray-600 text-lg">
                                    Selected :
                                </span>{" "}
                                <span className="font-semibold text-lg">
                                    {detailsData?.application_detail?.selected}{" "}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <Collapse defaultActiveKey={["1"]} onChange={onChange}>
                            <Panel header="Details" key="1">
                                <div>
                                    {detailsData?.job_data?.map((item) => {
                                        return (
                                            <div key={item.id} className="mb-4">
                                                <div>
                                                    <span className="font-semibold  text-gray-600 ">
                                                        Company Name :
                                                    </span>
                                                    <span className="font-semibold text-lg ml-2">
                                                        {item.company_name}
                                                    </span>
                                                </div>
                                                <div className="grid mt-3 grid-cols-2">
                                                    <div>
                                                        <span className="font-semibold  text-gray-600 ">
                                                            Job Name :
                                                        </span>
                                                        <span className="font-semibold text-lg ml-2">
                                                            {item.job_name}
                                                        </span>
                                                    </div>

                                                    <div>
                                                        <span className="font-semibold  text-gray-600 ">
                                                            Status :
                                                        </span>
                                                        <span className="font-semibold bg-blue-700 text-white rounded-md py-1 px-2 ml-2">
                                                            {item.status}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="border-b mt-4" />
                                            </div>
                                        );
                                    })}
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UserManagement;
