import {
  Box,
  Container,
  Grid,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
  Avatar,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../comp/Header";
import {
  getCompanyDetails,
  updateCompanyDetails,
} from "../../controller/companyController";
import { useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../utils/exceptionHandler";
import { ArrowBack, Save } from "@material-ui/icons";
import { HTTPStatus, allowedImageExtension } from "../../utils/constants";
import { message } from "antd";

/**
 * @type {import('../../model/CompanyModel').CompanyModel}
 */
const companyType = {
  name: "",
  companyEmail: "",
  companyFounder: "",
  companyCEO: "",
  contactPerson: "",
  contactEmail: "",
  contactNumber: "",
  companyEmployeeRange: "",
  address: "",
  city: "",
  state: "",
  country: "",
  pin: "",
  companyTagLine: "",
  companyDescription: "",
  companyLogo: "",
  companyUrl: "",
  workingSector: "",
  facebookUrl: "",
  establishDate: "",
  id: 0,
  linkedinUrl: "",
};

export default function CompanyDetailForm() {
  const [companyDetails, setCompanyDetails] = useState(companyType);
  const [companyLogo, setCompanyLogo] = useState();
  const navigate = useNavigate();
  const [updating, setUpdating] = useState(false);

  const handleCompanyDetails = useCallback(async () => {
    const res = await getCompanyDetails();
    if (res[0] !== null) {
      setCompanyDetails(res[0]);
    } else {
      handleControllerResultException(res[1], navigate);
    }
  }, [navigate]);

  useEffect(() => {
    handleCompanyDetails();
  }, [handleCompanyDetails]);

  /**
   *
   * @param { React.ChangeEvent<HTMLInputElement>} file
   */
  function handleImage(file) {
    if (file.target.files !== null && file.target.files.length === 1) {
      const fileExtension = file.target.files[0].type;
      const mainFile = file.target.files[0];
      if (allowedImageExtension.includes(fileExtension)) {
        setCompanyLogo(mainFile);
        setCompanyDetails((prevState) => ({
          ...prevState,
          companyLogo: URL.createObjectURL(mainFile),
        }));
      } else {
        message.error("Only images (jpeg, jpg, png, webp) are allowed");
        file.target.value = "";
      }
    } else {
      setCompanyLogo();
      file.target.value = "";
      setCompanyDetails((prevState) => ({ ...prevState, companyLogo: "" }));
    }
  }

  /**
   *
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  async function handleSubmit(e) {
    e.preventDefault();
    setUpdating(true);
    const res = await updateCompanyDetails({
      ...companyDetails,
      companyLogo,
    });
    setUpdating(false);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      navigate(-1)
      message.success(res.successMsg);
    } else {
      handleControllerResultException(res, navigate);
    }
  }

  return (
    <Box>
      <Header />
      <Container maxWidth="lg" sx={{ margin: "auto", padding: "3rem 0rem" }}>
        <Button
          onClick={() => navigate(-1)}
          type="submit"
          sx={{ marginBottom: "2rem" }}
        >
          <ArrowBack style={{ marginRight: "10px" }} />
          return
        </Button>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={6}>
            <Grid item xs={6} md={4}>
              <TextField
                label="Company name"
                value={companyDetails.name}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Company email"
                value={companyDetails.companyEmail}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    companyEmail: e.target.value,
                  }))
                }
                type="email"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Company founder"
                value={companyDetails.companyFounder}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    companyFounder: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Company CEO"
                value={companyDetails.companyCEO}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    companyCEO: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Contact person"
                value={companyDetails.contactPerson}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    contactPerson: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Contact Number"
                value={companyDetails.name}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    contactNumber: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Contact email"
                value={companyDetails.contactEmail}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    contactEmail: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="comployeeEmployeeRange">
                  Company employee range
                </InputLabel>
                <Select
                  labelId="comployeeEmployeeRange"
                  label="Company employee range"
                  value={companyDetails.companyEmployeeRange}
                  onChange={(e) =>
                    setCompanyDetails((prevState) => ({
                      ...prevState,
                      companyEmployeeRange: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={"0-10"}>0-10</MenuItem>
                  <MenuItem value={"10-50"}>10-50</MenuItem>
                  <MenuItem value={"50-100"}>50-100</MenuItem>
                  <MenuItem value={"100-300"}>100-300</MenuItem>
                  <MenuItem value={"300-1000"}>300-1000</MenuItem>
                  <MenuItem value={"1000+"}>1000+</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Country"
                value={companyDetails.country}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    country: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="State"
                value={companyDetails.state}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    state: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="City"
                value={companyDetails.city}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    city: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Pin"
                value={companyDetails.pin}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    pin: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                value={companyDetails.address}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    address: e.target.value,
                  }))
                }
                required
                fullWidth
                multiline
                rows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Company logo"
                onChange={(e) => handleImage(e)}
                fullWidth
                type="file"
                InputLabelProps={{ shrink: true }}
              />
              {companyDetails.companyLogo !== "" && (
                <Avatar
                  src={companyDetails.companyLogo}
                  alt={companyDetails.name}
                  sx={{ height: "80px", width: "80px", marginTop: "1rem" }}
                />
              )}
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Working sector"
                value={companyDetails.workingSector}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    workingSector: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Company tagline"
                value={companyDetails.companyTagLine}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    companyTagLine: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label="Company url"
                value={companyDetails.companyUrl}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    companyUrl: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Working sector"
                value={companyDetails.workingSector}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    workingSector: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Established date"
                value={companyDetails.establishDate}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    establishDate: e.target.value,
                  }))
                }
                type="date"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Company description"
                value={companyDetails.companyDescription}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    companyDescription: e.target.value,
                  }))
                }
                required
                fullWidth
                multiline
                rows={5}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Facebook URL"
                value={companyDetails.facebookUrl}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    facebookUrl: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Linkedin URL"
                value={companyDetails.linkedinUrl}
                onChange={(e) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    linkedinUrl: e.target.value,
                  }))
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent={"center"}>
                <Button disabled={updating} type="submit" variant="contained">
                  {!updating ? (
                    <>
                      {" "}
                      <Save style={{ marginRight: "10px" }} />
                      Save
                    </>
                  ) : (
                    <CircularProgress />
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
}
