import { ObjType } from "../utils/helperObj";

export default class HttpResponse {
  type = ObjType.httpResponse;

  /**
   *  @type {number}
   */
  statusCode;
  /**
   * @type {string | null}
   */
  successMsg;

  /**
   * @type {string | null}
   */
  errorMsg;

  /**
   * @type {unknown}
   */
  data;

  /**
   * @typedef {object} Props
   * @property {number} statusCode
   * @property {string | null} successMsg
   * @property {string | null} errorMsg
   * @property {unknown | null} data
   */
  /**
   * @param {Props} props
   */
  constructor({ statusCode, successMsg = null, errorMsg = null, data }) {
    this.statusCode = statusCode;
    this.successMsg = successMsg;
    this.errorMsg = errorMsg;
    this.data = data;
  }
}
