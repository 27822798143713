/**
 * @typedef {object} CompanyMailModel
 * @property {string} email
 * @property {string} name
 * @property {number} id
 * @property {number} userId
 */

/**
 * @typedef {object} CompanyModel
 * @property {number} id
 * @property {string} name
 * @property {boolean} status
 * @property {boolean} companyBlock
 * @property {string} companyEmail
 * @property {string} companyFounder
 * @property {string} companyCEO
 * @property {string} contactEmail
 * @property {string} contactPerson
 * @property {string} contactNumber
 * @property {string} companyEmployeeRange
 * @property {string} address
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {string} pin
 * @property {string} companyLogo
 * @property {string} companyTagLine
 * @property {string} companyDescription
 * @property {string} workingSector
 * @property {string} companyUrl
 * @property {string} facebookUrl
 * @property {string} linkedinUrl
 * @property {string} establishDate
 */

export function createCompanyMailObj(data) {
    /**
     * @type {CompanyMailModel}
     */
    const result = {
        email: data.email,
        name: data.name !== null ? data.name : "",
        id: data.id,
        userId: data.user_id,
    };
    return result;
}

export function createCompanyObj(data) {

    /**
     * @type {CompanyModel}
     */
    const result = {
        id: data.id,
        name: data.name,
        status: data.status,
        companyBlock: data.company_block,
        companyEmail: data.company_email,
        companyFounder: data.company_founder,
        companyCEO: data.company_ceo,
        contactEmail: data.contact_email,
        contactPerson: data.contact_person,
        contactNumber: data.contact_number,
        companyEmployeeRange: data.company_employee,
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        pin: data.pin,
        companyLogo: data.company_logo,
        companyTagLine: data.company_tagline !== null ? data.company_tagline : "",
        companyDescription: data.company_description,
        workingSector: data.working_sector,
        companyUrl: data.company_url,
        facebookUrl: data.facebook_url !== null ? data.facebook_url : "",
        linkedinUrl: data.linkedin_url !== null ? data.linkedin_url : "",
        establishDate: data.established_in
    }
    return result

}