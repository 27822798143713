import React from "react";
import { sanitize } from "dompurify";
import "./jobdesc.css";

function handleJson(data) {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
}

export default function RichDescription({ children }) {
  const sanitizedHTML = () => {
    const parsedHTML = handleJson(children);
    const sanitizedData = sanitize(parsedHTML);
    return (
      <div
        className="job_desc"
        dangerouslySetInnerHTML={{ __html: sanitizedData }}
      ></div>
    );
  };
  return <div>{sanitizedHTML()}</div>;
}
