import { Box, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import ApplicationLabelText from "./ApplicationLabelText";
import React from "react";

/**
 * @typedef {object} Props
 * @property {import('../../models/UserApplicationModel').UserApplicationModel} application
 */

/**
 * @param {Props} props
 */
export default function ApplicantPersonalDetails({ application }) {
    return (
        <Box marginY={"4rem"}>
            <Grid container spacing={4}>
                <Grid item lg={6} md={12}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={6}>
                            <ApplicationLabelText
                                label="Email"
                                text={application.email}
                            />
                        </Grid> */}
                        {application.role !== null && (
                            <Grid item xs={6}>
                                <ApplicationLabelText
                                    label="Role"
                                    text={application.role}
                                />
                            </Grid>
                        )}
                        {/* <Grid item xs={6}>
                            <ApplicationLabelText
                                label="Contact"
                                text={application.phoneNumber}
                            />
                        </Grid> */}
                        <Grid item xs={6}>
                            <ApplicationLabelText
                                label="Martial Status"
                                text={application.martialStatus}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ApplicationLabelText
                                label="Gender"
                                text={application.gender}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ApplicationLabelText
                                label="Parmanent Address"
                                text={application.address}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ApplicationLabelText
                                label="Language"
                                text={application.language}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ApplicationLabelText
                                label="Present Address"
                                text={application.currentAddress}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ApplicationLabelText
                                label="Date Of Birth"
                                text={application.dateOfBirth}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={6} md={12}>
                    <Typography variant="h6" fontWeight={"500"}>
                        Skills / Strengths
                    </Typography>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        flexWrap={"wrap"}
                    >
                        {application.userSkills.length > 0 &&
                            application.userSkills.map(data => (
                                <Box
                                    margin={"6px"}
                                    sx={{
                                        backgroundColor: blue[50],
                                        padding: "2px 15px",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <Typography
                                        fontSize={"13px"}
                                        color={blue[600]}
                                        fontWeight={"500px"}
                                    >
                                        {data.skill}
                                    </Typography>
                                </Box>
                            ))}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

