import Cookies from "universal-cookie";
import { handleException } from "../utils/dataFetchHelper";
import axios from "axios";
import { API } from "../utils/helperObj";
import { createBroadcastMessageObj } from "../model/BroadcastMessageModel";
const cookies = new Cookies();

/**
 * @returns {Promise<[import("../model/BroadcastMessageModel").BroadcastMessageModel[] | null, import("../model/HttpResponseModel").default | null ]>}
 */
export async function getBroadcastMessages() {
    const result = [null, null];
    try {
        const res = await axios.get(`${API.brodcastMessage.broadcastList}`, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });
        const messageArr = [];
        for (let x of res.data) {
            messageArr.push(createBroadcastMessageObj(x));
        }
        result[0] = messageArr;
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}
