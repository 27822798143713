import React, { useEffect, useState } from "react";
import Header from "../../comp/Header";
import { selectHeader } from "../features/HeaderSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import comp from "./profile.jpeg";
import { FaRegEdit } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import { RiCheckDoubleFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import { MobileNumberInput } from "../phoneInput/MobileNumberInput";
import { message } from "antd";

const cookies = new Cookies();

const EditProfile = () => {
  const navigate = useNavigate();

  const currentState = useSelector(selectHeader);

  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [empId, setEmpId] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [about, setAbout] = useState("");
  const [image, setImage] = useState("");
  const [otp, setOtp] = useState("");
  const [country, setCountry] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [error , setError] = useState(false)

  async function updateImage(e) {
    e.preventDefault();
    console.log(e.target.files[0]);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    console.log(formData);
    axios
      .put(`auth/user/profile/update/${id}`, formData, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setImage(formData);
        cookies.set("image", image);
        console.log(image);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function updateData(e) {
    e.preventDefault();

    if(name === ""){
      return message.error("Please Enter Name")
    }
    if(dob === ""){
      return message.error("Please Enter Date of Birth")
    }
    if(phone.length < 10){
      return message.error("Phone number should at least 10 digit")
    }
    if(empId === ""){
      return message.error("Please Enter Employee ID")
    }
    if(role === ""){
      return message.error("Please Enter Designation")
    }
    if(gender === ""){
      return message.error("Please Enter Gender")
    }
    if(state === ""){
      return message.error("Please Enter State")
    }
    if(city === ""){
      return message.error("Please Enter City")
    }
    if(country === ""){
      return message.error("Please Enter Country")
    }
    if(pinCode.length  < 6 || pinCode.length > 6){
      return message.error("Pin Code should be 6 digit")
    }
    if(address === ""){
      return message.error("Please Enter Address")
    }
    if(about === ""){
      return message.error("Please Enter About")
    }

    axios
      .put(
        `auth/user/profile/update/${id}`,
        {
          name: name,
          otp: otp,
          gender: gender,
          otp_phone: phone,
          role: role,
          employee_id: empId,
          dob: dob,
          about_you: about,
          country,
          pin: pinCode,
          state,
          city,
          address,
        },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        toast.success("Edited Sucessfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/Profile");
      })
      .catch(function (error) {
        setError(true)
        toast.error("Please re-check the form!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  async function getData() {
   
    await axios
      .get("auth/user/profile", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        const profileDetail = resp.data.user.profile;
        setEmail(resp.data.user.email);
        setId(resp.data.user.id);
        setName(resp.data.user.profile.name);
        setPhone(resp.data.user.profile.otp_phone);
        setDob(moment(resp.data.user.profile.dob).format("YYYY-MM-DD"));
        setEmpId(resp.data.user.profile.employee_id);
        setRole(resp.data.user.profile.role);
        setGender(resp.data.user.profile.gender);
        setAbout(resp.data.user.profile.about_you);
        setImage(resp.data.user.profile.image);
        setCountry(profileDetail.country !== null ? profileDetail.country : "");
        setPinCode(profileDetail.pin !== null ? profileDetail.pin : "");
        setState(profileDetail.state !== null ? profileDetail.state : "");
        setCity(profileDetail.city !== null ? profileDetail.city : "");
        setAddress(profileDetail.address !== null ? profileDetail.address : "");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setOtp(cookies.get("otp"));
    setId(cookies.get("id"));
    getData();
  }, [image]);
  return (
    <>
      <Header />

      <Container maxWidth="md" sx={{ margin: "5rem auto" }}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Box>
            <Box sx={{position:"relative"}}>
              <img
                  src={image ? image : comp}
                  alt=""
                  width="100px"
                  height="100px"
                  style={{
                    borderRadius: "50%",
                    objectFit: "fill",
                    width: "100px",
                    height: "100px",
                  }}
                />
                <label className="">
                  <FaRegEdit  className="absolute mt-[-45px] ml-8 text-lg xl:text-lg 2xl:text-2xl md:text-base  cursor-pointer" />
                  <input
                    className="w-20"
                    type="file"
                    onChange={updateImage}
                    style={{ visibility: "hidden" }}
                                        />
                                    </label>
            </Box>
              <Typography
                variant="body1"
                fontSize={"1.2rem"}
                marginTop={"1rem"}
              >
                {email}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent={"flex-end"}>
              <Button variant="contained" onClick={updateData}>
                Save <RiCheckDoubleFill style={{ marginLeft: "1rem" }} />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              label="Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              value={dob}
              variant="outlined"
              label="Date of birth"
              type="date"
              onChange={(e) => setDob(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <MobileNumberInput
              initVal={phone}
              onChange={(val) => setPhone(val)}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              onChange={(e) => setEmpId(e.target.value)}
              value={empId}
              label="Employee ID"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              onChange={(e) => setRole(e.target.value)}
              value={role}
              label="Designation"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="profile-gender">Gender</InputLabel>
              <Select
                label="Gender"
                labelId="profile-gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                fullWidth
                variant="outlined"
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              onChange={(e) => setState(e.target.value)}
              value={state}
              label="State"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              onChange={(e) => setCity(e.target.value)}
              value={city}
              label="City"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              onChange={(e) => setCountry(e.target.value)}
              value={country}
              label="Country"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              onChange={(e) => setPinCode(e.target.value)}
              value={pinCode}
              label="Pincode"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              value={address}
              label="Address"
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              value={about}
              label="About"
              onChange={(e) => setAbout(e.target.value)}
              fullWidth
              multiline
              rows={5}
              sx={{marginBottom:5}}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EditProfile;
