/**
 * @typedef {object} JobApplicationModel
 * @property {number} id
 * @property {number} assessmentId
 * @property {boolean} isAssessment
 * @property {boolean} isCompanyBookmark
 * @property {string} jobName
 * @property  {number} positionId
 * @property {string} resumeFileName
 * @property {string} resumeFileLink
 * @property {string} applicantFirstName
 * @property {string} applicantLastName
 * @property {string} applicantProfilePic
 * @property {number}  applicantId
 * @property {string} status
 * @property {string} appliedDate
 * @property {string} applicantSpecialization
 * @property {import("./UserApplicationModel").UserSkillModel[]} skills
 */

import { createdAtToDate } from "../utils/helperFunction";
import { createUserSkillObj } from "./UserApplicationModel";



export function createJobApplicationObj(data) {

    /**
     * @type {JobApplicationModel}
     */
    const result = {
        id: data.id,
        apliedId: data.appli_id,
        assessmentId: data.assessment_id,
        isAssessment: data.is_assessment,
        isCompanyBookmark: data.company_bookmark,
        jobName: data.role,
        positionId: data.position,
        resumeFileName: data.resume_name,
        resumeFileLink: data.user_resume,
        applicantFirstName: data.first_name,        
        applicantLastName: data.last_name,
        applicantProfilePic: data.profile_pic,
        applicantId: data.user,
        appliedDate: createdAtToDate(data.created_at),
        status: data.application_status,
        applicantSpecialization: data.user_specialization,
        skills: []
    }
    if (data.skills !== null) {
        for (let x of data.skills) {
            result.skills.push(createUserSkillObj(x))
        }
    }
    
    return result
}
